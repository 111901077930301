/* App.css */
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  background-color: #EFF3F4;
}

h1 {
  color: #001711;
}
hr.br-rcustom {
  color: #00B990;
  border: 1px solid #00B990;
}

#tecnos-icon {
  color: #00B990;
  padding-right: 5px;
}

button.btn-info,
a.btn-info {
  background-color: #D7FFFD;
  color: #009E91;
  border-color: #D7FFFD;
  font-weight:600;
}
button.btn-info:hover,
a.btn-info:focus,
a.btn-info:active,
a.btn-info:hover {
  background-color: #D7FFFD !important;
  color: #009E91;
  border-color: #009E91;
}

.card {
  min-height: 280px;
}

.card-body span.btn {
  border-color: #009E91;
  margin: 0.5rem;
}